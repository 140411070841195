@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,800');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300');

* {
    outline: none !important;
    text-decoration: none !important;
}

body {
    font-family: "Open Sans",sans-serif;
}

a, a:hover {
    color: inherit;
}

hr {
    background-color: #bbb
}

.carousel-item iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.carousel-control-next, .carousel-control-prev {
    bottom: 60px !important;
}

.carousel-indicators {
    right: auto !important;
    left: auto !important;
    margin-left: initial !important;
    margin-right: initial !important;
    margin-bottom: 0.5rem !important;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6 !important;
}

.table td, .table th {
    padding: .75rem !important;
    vertical-align: initial !important;
    border-top: 1px solid #dee2e6;
    border-bottom: 0 !important;
}

.align-center #rifas {
    justify-content: center!important;
}

.bg-success {
    background-color: #00d539!important;
    color: #fff!important;
}

.bg-disabled {
    background-color: #dedede!important;
}

.bg-f5 {
    background-color: #f5f5f5 !important;
}

.pre-line {
    white-space: pre-line
}

.pointer {
    cursor: pointer;
}

.label-custom {
    color: #2c2c31;
    font-weight: 600;
    font-family: "Open Sans",sans-serif;
}

.header .nav-link {
    font-weight: 600;
    padding: 28px 1.5rem !important;
    border-bottom: 2px solid transparent;
}

@media (max-width: 548px) {
    .navbar-nav {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .header .nav-link {
        font-weight: 600;
        padding: 15px 1.5rem !important;
    }
}

.header .nav-link:hover {
    opacity: 0.7;
}

.header .nav-link .icon-lg {
    font-size: 22px;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.card {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 hsl(0deg 0% 60% / 20%);
    border-radius: 3px;
    border: none;
}

.about h4 {
    color: #12284C;
    font-weight: 700;
    font-size: 20px;
}
.logo {
    max-height: 50px;
}

@media (max-width: 548px) {
    .logo {
        max-height: 40px;
    }

    .about h4 {
        font-size: 14px;
    }

    .about p {
        font-size: 12px;
    }
}


.title-default {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 800;
    color: #95D4E9;
    text-align: center;
    font-size: 68px;
    font-style: italic;
}

@media (max-width: 548px) {
	.title-default {
		font-size: 2rem;
	}
}

@keyframes pulse {
    0% {box-shadow: 0 0 0 0 rgba(112, 212, 75, 0.7)}
    70% {box-shadow: 0 0 0 10px rgba(112, 212, 75, 0)}
    100% { box-shadow: 0 0 0 0 rgba(112, 212, 75, 0)}
}

.icon-ativas:before {
    content: '';
    animation: pulse 1.5s ease infinite;
    -webkit-animation:pulse 1.5s ease infinite;
    width: 18.38px;
    border-radius: 50%;
    background-color: #28a745;
    height: 15px;
    margin-left: 7px;
}

.icon-realizadas:before {
    content: '';
    width: 17.79px;
    border-radius: 50%;
    background-color: #ffc107;
    height: 15px;
    margin-right: 7px;
}

.btn-success {
    background-image: linear-gradient(173deg,#32dc00,#02ab15) !important;
    color: #fff !important;
}

.btn-success:hover {
    background-image: linear-gradient(173deg,#278f07,#02ab15);
}

.questions h3 {
    font-family: 'Fira Sans', sans-serif;
    font-weight: 800;
    text-align: center;
    font-size: 50px;
    font-style: italic;
}

@media (max-width: 548px) {
    .questions h3 {
        font-size: 1.7rem;
    }
}

.questions .card-expansion-item {
    margin-bottom: 0;
    border-radius: 0;
    transition: margin .2s;
    box-shadow: 0 0 0 1px rgb(20 20 31 / 5%), 0 1px 3px 0 rgb(20 20 31 / 15%);
}

.questions .card-expansion-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.questions .card-header {
    padding: 1rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid rgba(20,20,31,.12);
}

.questions .card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.questions .card-header strong {
    color: #363642
}

[data-bs-toggle=collapse] .collapse-indicator>svg {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s,-webkit-transform .2s;
}

[data-bs-toggle=collapse][aria-expanded=true] .collapse-indicator>svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.title {
    font-style: italic;
    font-family: "Fira Sans",sans-serif;
    font-weight: 700;
}

.group-filter button {
    margin-right: 0.5rem;
}

.badge-secondary {
    color: #363642;
    background-color: #eee;
    font-weight: bolder;
}

.bilhetes .itens button {
    width: 3rem !important;
    height: 2rem !important;
    margin: 0.15rem;
    margin-bottom: 0.3rem;
    font-size: 0.9rem !important;
    font-weight: bolder;
}

@media (max-width: 767px) {
    .bilhetes .itens button {
        margin: 0.25rem;
    }
}

.next-check-out {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

@media (max-width: 548px) {
    .bilhetes .itens button {
        width: calc((100vw - 2.5rem - 20px) / 5) !important;
        min-width: auto;
    }

	.form-user .MuiDialog-scrollPaper {
		align-items: start !important;
	}
	.form-user .MuiDialog-paper {
		margin: 10px !important;
	}
	.itens {
		margin-left: -10px;
		margin-right: -10px;
	}
	.group-filter button .badge {
		position: absolute;
		top: -10px;
		right: 0px;
	}
    .MuiDialog-paper {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
	.checkout .card-body {
        background-color: #f7fbff;
        border-top: 2px solid #eee;
    }
}

.checkout .btn-pay {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    display: block;
    width: 100%;
    cursor: pointer;
    font-family: "Helvetica Neue", Arial, sans-serif;
    background: #009ee3;
    color: #fff;
    text-align: center;
}

@media (min-width: 549px) {
    .checkout {
        background-color: #f7fbff;
        border-left: 2px solid #eee;
    }
}


.MuiDialog-scrollPaper {
    align-items: baseline !important;
}

.MuiDialog-paperScrollPaper {
    max-height: inherit !important;
}

.MuiDialog-root {
    overflow: auto;
}

#button-checkout {
    min-height: 45.71px;
}

#button-checkout button[type=submit] {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    display: block;
    width: 100%;
}

.ganhador {
    animation: pulse 1.5s ease infinite;
    -webkit-animation:pulse 1.5s ease infinite;
}

.ganhador .extracao {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -8px;
    background-color: #eee;
    border-radius: 4px;
    padding: 0 2px;
    border: 2px solid #ccc;
}

#footer {
    position: absolute;
    width: 100%;
}

body {
    min-height: 100vh;
}

#root {
    position: relative
}

.checkout #root {
    display: flex;
    align-items: center;
}

.checkout body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
}

.checkout form {
    width: 100%;
    align-self: center;
}
.checkout input {
    border-radius: 6px;
    margin-bottom: 6px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}
.checkout .result-message {
    line-height: 22px;
    font-size: 16px;
}
.checkout .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
}
.checkout .hidden {
    display: none;
}
.checkout #card-error {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
}
.checkout #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}
.checkout #payment-request-button {
    margin-bottom: 32px;
}

.checkout button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    margin-top: 20px;
}
.checkout button:hover {
    filter: contrast(115%);
}
.checkout button:disabled {
    opacity: 0.5;
    cursor: default;
}

.checkout .spinner,
.checkout .spinner:before,
.checkout .spinner:after {
    border-radius: 50%;
}
.checkout .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.checkout .spinner:before,
.checkout .spinner:after {
    position: absolute;
    content: "";
}
.checkout .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}
.checkout .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}
@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@media only screen and (max-width: 600px) {
    form {
        width: 80vw;
    }
}